import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute right-0 bottom-0 mr-2 mb-2 text-gray-lp-600 text-12px xxl:text-14px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_component_DataWrapper, {
    class: "w-9/12 relative",
    label: "No. STT"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_multiselect, {
        class: "my-2",
        modelValue: _ctx.sttNumbers,
        "onUpdate:modelValue": _ctx.onUpdateSttNumbers,
        placeholder: _ctx.$t('cnManifest.placeholderSttNo'),
        "tag-placeholder": "Press enter to add No. STT",
        label: "name",
        "track-by": "id",
        options: _ctx.sttNumbersOptions,
        maxLength: 50,
        multiple: true,
        searchable: true,
        taggable: true,
        "preserve-search": true,
        onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchSttNumbersOptions($event.target.value))),
        isTextArea: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "options"]),
      _createVNode("div", _hoisted_1, _toDisplayString(_ctx.sttNumbers.length) + "/50 ", 1)
    ]),
    _: 1
  }))
}