import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col text-12px xxl:text-14px overflow-y-auto h-screen relative w-full" }
const _hoisted_2 = { class: "grid gap-6 grid-cols-3" }
const _hoisted_3 = { class: "flex gap-x-6 flex-row mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Select = _resolveComponent("Select")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_filter_by_date = _resolveComponent("filter-by-date")!
  const _component_filter_by_stt = _resolveComponent("filter-by-stt")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumb),
    _createVNode(_component_Header, {
      ableToFilter: true,
      currentRoutes: _ctx.getCurrentRoute,
      onOnToggleShowFilter: _ctx.setIsShowFilter
    }, {
      filter: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            label: _ctx.$t('cnManifest.filterTypeSttListing')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Select, {
                dataSelect: _ctx.sttTypeOptions,
                onOpenSelect: _ctx.onOpenSttType,
                onCloseSelect: _ctx.onCloseSttType,
                onChooseSelect: _ctx.onSelectSttType,
                value: _ctx.sttListingType,
                isOpenMenu: _ctx.sttType,
                class: "w-full z-20 mt-2"
              }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.sttListingType === 'booking_date')
            ? (_openBlock(), _createBlock(_component_filter_by_date, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        (_ctx.sttListingType === 'stt_list')
          ? (_openBlock(), _createBlock(_component_filter_by_stt, {
              key: 0,
              class: "mt-6"
            }))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_LpButton, {
            disabled: _ctx.isDisabledSearch,
            onClick: _ctx.onRequest,
            customClass: "w-40",
            iconLeft: _ctx.isRefreshIcon ? 'refresh-white' : '',
            title: "Search",
            textColor: "white"
          }, null, 8, ["disabled", "onClick", "iconLeft"]),
          _createVNode(_component_LpButton, {
            onClick: _cache[1] || (_cache[1] = () => (_ctx.isDisabledSearch ? null : _ctx.exportToCsv())),
            disabled: _ctx.isDisabledSearch,
            outline: "",
            customClass: "px-8",
            iconLeft: 
              _ctx.isDisabledSearch
                ? 'document-download-outline-grey'
                : 'document-download-outline'
            ,
            title: "Export to CSV",
            textColor: "red-lp-300"
          }, null, 8, ["disabled", "iconLeft"])
        ])
      ]),
      _: 1
    }, 8, ["currentRoutes", "onOnToggleShowFilter"]),
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause
        }, null, 8, ["errorType"]))
      : (_ctx.isEmpty)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            customMessage: _ctx.$t('cnManifest.emptyListMessage')
          }, null, 8, ["customMessage"]))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 2,
            customClassPagination: 'relative',
            class: "my-2",
            borderTop: false,
            hidePagination: _ctx.filter.sttListingType === 'stt_list',
            columns: _ctx.columns,
            onRequest: _ctx.onRequest,
            data: _ctx.cnManifests.data,
            pagination: _ctx.cnManifests.pagination,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.cnManifests.pagination = $event)),
            loading: _ctx.isLoading,
            isRightFreeze: true,
            totalColumnFreeze: 0,
            pinnedSubstractHeight: 
        _ctx.isShowFilter
          ? _ctx.filter.sttListingType === 'stt_list'
            ? '680px'
            : '530px'
          : '250px'
      ,
            isNoPaddingBottom: ""
          }, null, 8, ["hidePagination", "columns", "onRequest", "data", "pagination", "loading", "pinnedSubstractHeight"]))
  ]))
}