
import { Options, Vue } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { CnManifestController } from "@/app/ui/controllers/CnManifestController";
import debounce from "lodash/debounce";

@Options({
  components: {},
  watch: {
    dataProfile() {
      this.setDefaultFilter();
    }
  }
})
export default class FilterByDate extends Vue {
  mounted() {
    this.setDefaultFilter();
    this.fetchClientPartnerOptions("");
    this.fetchListDestinationCity("");
  }

  setDefaultFilter() {
    let userType = "console";
    let clientPartner: any = "";
    if (!this.isInternal) {
      userType =
        this.dataProfile.account_type_detail.type ||
        this.dataProfile.account_type.toLowerCase();
      clientPartner = {
        id: this.dataProfile.account_type_detail.id,
        name: this.dataProfile.account_type_detail.company_name
      };
    }
    CnManifestController.setCnManifestFilter({
      key: "userType",
      value: userType
    });
    this.onUpdateClientPartner(clientPartner);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get isInternal() {
    return this.dataProfile.account_type.toLowerCase() === "internal";
  }

  // Tipe User
  userTypeSelect = false;
  userTypeOptions = [
    {
      name: "Consolidator",
      value: "console"
    },
    {
      name: "Subconsolidator",
      value: "sub-console"
    },
    {
      name: "POS",
      value: "pos"
    },
    {
      name: "Client",
      value: "client"
    },
    {
      name: "Customer",
      value: "customer"
    }
  ];
  get userType() {
    return CnManifestController.cnManifestFilter.userType;
  }
  onSelectUserType(name: string, value: string) {
    if (this.userType !== value) {
      CnManifestController.setCnManifestFilter({
        key: "userType",
        value: value
      });
      this.onUpdateClientPartner("");
      CnManifestController.initListClientPartnerOptions();
      this.fetchClientPartnerOptions("");
    }
    this.onCloseUserType();
  }
  onOpenUserType() {
    this.userTypeSelect = true;
  }
  onCloseUserType() {
    this.userTypeSelect = false;
  }

  // client / partner
  get clientPartner() {
    return CnManifestController.cnManifestFilter.clientPartner;
  }
  onUpdateClientPartner(value: any) {
    CnManifestController.setCnManifestFilter({
      key: "clientPartner",
      value: value
    });
  }
  get clientPartnerOptions() {
    return CnManifestController.clientPartnerOptions;
  }
  get loadingClientPartnerOptions() {
    return CnManifestController.loadingClientPartnerOptions;
  }
  fetchClientPartnerOptions = debounce((search: string) => {
    if (search.length < 3 && search.length > 0) {
      return;
    }
    CnManifestController.fetchListClientPartnerOptions({
      page: 1,
      limit: 7,
      search: search,
      isParent: false
    });
  }, 250);

  // city destination
  get destinationCity() {
    return CnManifestController.cnManifestFilter.destinationCity;
  }
  get destinationCityOptions() {
    return [
      { id: 0, name: "All", code: "" },
      ...CnManifestController.destinationCityOptions
    ];
  }
  get loadingDestinationCityOptions() {
    return CnManifestController.loadingDestinationCityOptions;
  }
  onUpdateDestinationCity(value: any) {
    CnManifestController.setCnManifestFilter({
      key: "destinationCity",
      value: value
    });
  }
  fetchListDestinationCity = debounce((search: string) => {
    if (search.length > 2 || !search) {
      CnManifestController.fetchListDestinationCityOptions({
        page: 1,
        limit: 7,
        search: search
      });
    }
  }, 250);

  // start date -  end date
  invalidStartDate = false;
  invalidEndDate = false;
  invalidDuration = false;
  get startDate() {
    return CnManifestController.cnManifestFilter.startDate;
  }
  get endDate() {
    return CnManifestController.cnManifestFilter.endDate;
  }
  onUpdateStartDate(value: string) {
    CnManifestController.setCnManifestFilter({
      key: "startDate",
      value: value
    });
  }
  onUpdateEndDate(value: any) {
    CnManifestController.setEndDate(value);
  }

  validateDateStartEnd() {
    const dateStart = new Date(this.startDate);
    const dateEnd = new Date(this.endDate);
    this.invalidStartDate = dateStart.getTime() > dateEnd.getTime();
    this.invalidEndDate = dateEnd.getTime() < dateStart.getTime();
  }
}
