import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = { class: "text-red-lp-100 text-12px xxl:text-14px" }
const _hoisted_4 = { class: "text-red-lp-100 text-12px xxl:text-14px whitespace-no-wrap" }
const _hoisted_5 = { class: "text-red-lp-100 text-12px xxl:text-14px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_DatePickers = _resolveComponent("DatePickers")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DataWrapper, {
      label: _ctx.$t('cnManifest.filterTypeUser')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Select, {
          isDisabled: !_ctx.isInternal,
          dataSelect: _ctx.userTypeOptions,
          onOpenSelect: _ctx.onOpenUserType,
          onCloseSelect: _ctx.onCloseUserType,
          onChooseSelect: _ctx.onSelectUserType,
          value: _ctx.userType,
          isOpenMenu: _ctx.userTypeSelect,
          class: "w-full z-20 mt-2"
        }, null, 8, ["isDisabled", "dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_DataWrapper, {
      label: _ctx.$t('cnManifest.filterPartner')
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_SelectSearch, {
            onFilter: _ctx.fetchClientPartnerOptions,
            isLoading: _ctx.loadingClientPartnerOptions,
            isDisabled: !_ctx.isInternal,
            keyValue: "id",
            keyName: "name",
            options: _ctx.clientPartnerOptions,
            placeholder: "Ketik atau pilih nama client/partner",
            modelValue: _ctx.clientPartner,
            "onUpdate:modelValue": _ctx.onUpdateClientPartner
          }, null, 8, ["onFilter", "isLoading", "isDisabled", "options", "modelValue", "onUpdate:modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_DataWrapper, {
      label: _ctx.$t('cnManifest.filterDestinationCity')
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_SelectSearch, {
            onFilter: _ctx.fetchListDestinationCity,
            isLoading: _ctx.loadingDestinationCityOptions,
            keyValue: "id",
            keyName: "name",
            options: _ctx.destinationCityOptions,
            placeholder: _ctx.$t('cnManifest.placeholderDestinationCity'),
            modelValue: _ctx.destinationCity,
            "onUpdate:modelValue": _ctx.onUpdateDestinationCity
          }, null, 8, ["onFilter", "isLoading", "options", "placeholder", "modelValue", "onUpdate:modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_DataWrapper, {
      label: _ctx.$t('cnManifest.filterDateStart')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DatePickers, {
          modelValue: _ctx.startDate,
          "onUpdate:modelValue": _ctx.onUpdateStartDate,
          onSelected: _ctx.validateDateStartEnd,
          placeholder: _ctx.$t('placeholderDatePicker'),
          class: "my-2 w-full"
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onSelected", "placeholder"]),
        _withDirectives(_createVNode("div", _hoisted_3, " Tanggal mulai tidak boleh lebih dari tanggal akhir ", 512), [
          [_vShow, _ctx.invalidStartDate]
        ]),
        _withDirectives(_createVNode("div", _hoisted_4, " Periode booking yang dapat dipilih max 14 hari ", 512), [
          [_vShow, _ctx.invalidDuration]
        ])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_DataWrapper, {
      label: _ctx.$t('cnManifest.filterDateEnd')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DatePickers, {
          modelValue: _ctx.endDate,
          "onUpdate:modelValue": _ctx.onUpdateEndDate,
          onSelected: _ctx.validateDateStartEnd,
          placeholder: _ctx.$t('placeholderDatePicker'),
          minDate: new Date(_ctx.$moment(_ctx.startDate).toISOString()),
          maxDate: 
        new Date(
          _ctx.$moment(_ctx.startDate)
            .add('14', 'days')
            .toISOString()
        )
      ,
          class: "my-2 w-full"
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onSelected", "placeholder", "minDate", "maxDate"]),
        _withDirectives(_createVNode("div", _hoisted_5, " Tanggal akhir tidak boleh kurang dari tanggal mulai ", 512), [
          [_vShow, _ctx.invalidEndDate]
        ])
      ]),
      _: 1
    }, 8, ["label"])
  ], 64))
}