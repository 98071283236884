
import { Options, Vue } from "vue-class-component";
import Multiselect from "@/app/ui/components/multiselect-search-tag/index.vue";
import { CnManifestController } from "@/app/ui/controllers/CnManifestController";
import debounce from "lodash/debounce";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";

@Options({
  components: {
    Multiselect
  },
  watch: {
    sttNumbers() {
      if (this.sttNumbers.length === 0) {
        CnManifestController.initCnManifests();
      }
    }
  }
})
export default class FilterByStt extends Vue {
  mounted() {
    this.fetchSttNumbersOptions("");
  }
  // Fetch No. STT
  fetchSttNumbersOptions = debounce((search: string) => {
    CnManifestController.fetchListSttNumbersOptions(
      new RequestListBooking({
        page: 1,
        limit: 7,
        search: search,
        isPaidUnpaid: false,
        paymentStatus: ""
      })
    );
  }, 250);
  get sttNumbersOptions() {
    return CnManifestController.sttNumbersOptions;
  }
  get sttNumbers() {
    return CnManifestController.cnManifestFilter.sttNumbers;
  }
  onUpdateSttNumbers(value: Array<any>) {
    CnManifestController.setCnManifestFilter({
      key: "sttNumbers",
      value: value
    });
  }
}
