
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import {
  convertDecimalWithComma,
  formatDate,
  formatPrice,
} from "@/app/infrastructures/misc/Utils";
import FilterByDate from "@/app/ui/views/shipment/cn-manifest/component/filter-by-date.vue";
import FilterByStt from "@/app/ui/views/shipment/cn-manifest/component/filter-by-stt.vue";
import { CnManifestController } from "@/app/ui/controllers/CnManifestController";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
  components: {
    EmptyState,
    FilterByDate,
    FilterByStt,
  },
})
export default class CnManifest extends Vue {
  created() {
    CnManifestController.initCnManifestFilter();
    CnManifestController.initCnManifests();
  }
  get getCurrentRoute() {
    return this.$route.meta.name;
  }
  get filter() {
    return CnManifestController.cnManifestFilter;
  }
  get cacheFilter() {
    return CnManifestController.cacheCnManifestFilter;
  }
  get isDisabledSearch() {
    if (this.filter.sttListingType === "stt_list") {
      return this.filter.sttNumbers.length === 0;
    }
    if (this.filter.sttListingType === "booking_date") {
      return (
        this.filter.clientPartner === "" ||
        this.filter.destinationCity === "" ||
        this.filter.startDate === "" ||
        this.filter.endDate === ""
      );
    }
    return true;
  }

  // handle toggle filter
  isShowFilter = true;
  setIsShowFilter(val: boolean) {
    this.isShowFilter = val;
  }

  // Table
  get isEmpty() {
    return (
      this.cnManifests.data.length === 0 && this.firstRequest && !this.isLoading
    );
  }
  get isRefreshIcon() {
    return (
      !this.firstRequest &&
      !this.isLoading &&
      JSON.stringify(this.filter.sttNumbers) !==
        JSON.stringify(this.cacheFilter.sttNumbers)
    );
  }
  get firstRequest() {
    return CnManifestController.firstRequest;
  }
  get isError() {
    return CnManifestController.isError;
  }
  get isLoading() {
    return CnManifestController.loadingCnManifests;
  }
  get errorCause() {
    return CnManifestController.errorCause;
  }
  get cnManifests() {
    return CnManifestController.cnManifests;
  }
  isAccountCountryOutsideId(bookedBy: string) {
    if (AccountController.accountData.isInternalAccount) {
      return bookedBy === "MY"
    }
    return (
      AccountController.accountData.account_type_detail.countryCode?.toLocaleLowerCase() !==
      "id"
    );
  }
  get columns() { 
    return [
    {
      name: "No.",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: any, index: number) => {
        return `<div class="text-black-lp-300">${index + 1}</div>`;
      },
    },
    {
      name: this.$t("Tanggal Booking"),
      styleHead: "w-60 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.sttCreatedAt
        )}</div>`;
      },
    },
    {
      name: "No. STT",
      styleHead: "w-50 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${item.sttNo}</div>`;
      },
    },
    {
      name: this.$t("Kota Tujuan"),
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttDestinationCity}</div>`;
      },
    },
    {
      name: this.$t("Total Koli"),
      styleHead: "w-32 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttTotalPiece}</div>`;
      },
    },
    {
      name: this.$t("Total Berat"),
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${convertDecimalWithComma(
          item.sttChargeAbleWeight,
          2
        )} Kg</div>`;
      },
    },
    {
      name: "Total Volume",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${convertDecimalWithComma(
          item.sttVolumeWeight,
          2
        )}</div>`;
      },
    },
    {
      name: this.$t("Produk"),
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttProductType}
                  </div>
                </div>`;
      },
    },
    {
      name: this.$t("Penerima"),
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttRecipientName}</div>`;
      },
    },
    {
      name: this.$t("Alamat Penerima"),
      styleHead: "w-64 text-left",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttRecipientAddress}</div>`;
      },
    },
    {
      name: this.$t("No. Telp Penerima"),
      styleHead: "w-60 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttRecipientPhone}</div>`;
      },
    },
    {
      name: this.$t("Tipe Komoditas"),
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttCommodityName}</div>`;
      },
    },
    {
      name: "HS Code",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttHsCode}</div>`;
      },
    },
    {
      name: this.$t("NPWP"),
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttTaxNumber}</div>`;
      },
    },
    {
      name: this.$t("Harga Barang"),
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${formatPrice(item.sttGoodsEstimatePrice, "", this.isAccountCountryOutsideId(item.sttBookedByCountry) ? "MYR" : "IDR")}</div>`;
      },
    },
    {
      name: "BM",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttTaxStamp.toLocaleString(
          "id"
        )}</div>`;
      },
    },
    {
      name: "PPN",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttValueAddedTax.toLocaleString(
          "id"
        )}</div>`;
      },
    },
    {
      name: "PPH",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttIncomeTax.toLocaleString(
          "id"
        )}</div>`;
      },
    },
    {
      name: "Total Good Tax",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttTotalGoodTax.toLocaleString(
          "id"
        )}</div>`;
      },
    },
    {
      name: this.$t("Pengenaan Pajak"),
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttTaxImposition}</div>`;
      },
    },
    {
      name: "Status",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded-full capitalize px-2 py-0 bg-gray-lp-400">
                      ${item.sttLastStatus}
                  </div>
                </div>`;
      },
      toolTip: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.sttLastStatusDescription}</div>`;
      },
    },
    {
      name: "Booked By",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.sttBookedBy}</div>`;
      },
    },
    {
      name: "Total Amount",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${formatPrice(
          item.sttTotalAmount, "",this.isAccountCountryOutsideId(item.sttBookedByCountry) ? "MYR" : "IDR"
        )}</div>`;
      },
    },
  ]}
  onRequest() {
    if (this.isDisabledSearch) {
      return;
    }
    CnManifestController.fetchCnManifests();
  }
  exportToCsv() {
    CnManifestController.exportManifestsToCsv();
  }

  // STT Listing
  sttType = false;
  get sttTypeOptions() { 
    return [
    {
      name: this.$t("Tanggal Booking"),
      value: "booking_date",
    },
    {
      name: "No. STT / No. Ref",
      value: "stt_list",
    },
  ]}
  get sttListingType() {
    return CnManifestController.cnManifestFilter.sttListingType;
  }
  onSelectSttType(name: string, value: string) {
    if (this.sttListingType !== value) {
      CnManifestController.initCnManifestFilter();
      CnManifestController.initCnManifests();
      CnManifestController.setCnManifestFilter({
        key: "sttListingType",
        value: value,
      });
    }
    this.onCloseSttType();
  }
  onOpenSttType() {
    this.sttType = true;
  }
  onCloseSttType() {
    this.sttType = false;
  }
}
